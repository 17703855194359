<template>
  <div>
    <CrudIndex
      v-bind="this.$store.state.stone_model[_modelName]"
      :params="_params"
      :label="_label"
      :urlModelName="_urlModelName"
      :getUrl="_getUrl"
      :createUrl="_createUrl"
      bindingKey="index_my"
    ></CrudIndex>
  </div>
</template>

<script>
export default {
  computed: {
    _getUrl() {
      return `/my/${this._urlModelName}`;
    },
    _createUrl() {
      return `/my/${this._urlModelName}/create`;
    },
    _modelName() {
      return this.$route.meta.modelName;
    },
    _params() {
      let _params = {};
      if (this.$route.meta.params) {
        _params = this.$route.meta.params;
      }
      return _params;
    },
    _label() {
      let _label = "";
      if (this.$route.meta && this.$route.meta.label) {
        _label = this.$route.meta.label;
      }
      if (this.$store.state.stone_model[this._modelName].label) {
        _label = this.$store.state.stone_model[this._modelName].label;
      }
      return _label;
      // return this.$route.meta.label ? this.$route.meta.label : null;
    },
    _urlModelName() {
      return this.$route.meta.urlModelName
        ? this.$route.meta.urlModelName
        : this._modelName;
    },
  },
  mounted() {},
};
</script>